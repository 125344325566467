import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { Row, Col } from "react-styled-flexboxgrid";
import { navigateTo } from "gatsby-link";
import { Primary } from "../Section";
import { Button } from "../Button";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "mj-with-book.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: top center;
  background-repeat: repeat-y;
  height: 700px;
`;

const Mj = () => (
  <section>
    <StyledBackgroundSection />
    <Primary centered>
      <Row>
        <Col xs={12}>
          <h2>Michael S. Joyner, MD </h2>
          <h3>Founder, Chairman and CEO of MJMD Global Group</h3>
          <h3>Hand and Microsurgeon</h3>
          <p>
            After a surgical internship, Dr. Joyner served as a US Army General
            Medical Officer at the Presidio of Monterey Army Health Clinic. He
            was the Primary Brigade Surgeon for the US Air Force 311th Training
            Squadron at the Defense Language Institute from 1996 to 2000. He
            completed his military obligation as a Major in the United States
            Army.
          </p>
          <p>
            Dr. Joyner, a Loyola High School Alumnus, received his undergraduate
            training at UC Berkeley and Howard University. He is a graduate of
            Georgetown University School of Medicine. Dr. Joyner received his
            postgraduate training in General Surgery at Morehouse School of
            Medicine in Atlanta, Georgia. While serving as Chief General Surgery
            resident at Grady Memorial Hospital Dr. Joyner became very
            experienced in Trauma Surgery along with receiving The Avon Breast
            Cancer Center Award for service and care in surgical oncology and
            reconstructive surgery. He served as a Christin M. Kleinert Hand
            Fellow in 2005 and a visiting microsurgical scholar to Chang-Gung
            Memorial Hospital, Taipei, Taiwan in 2006.
          </p>
          <p>
            Prior to The Joyner Hand, Dr. Joyner was a staff Hand and
            Microsurgeon for Kleinert, Kutz, and Associates in Lexington,
            Kentucky. He was also a clinical instructor for the University of
            Louisville. Not only is Dr. Michael J the leading Hand Surgeon for
            The Joyner Hand, PA, who is focused on the best surgical and
            nonsurgical care for his clients, he is also considered a
            renaissance man. He is the Founder, Chairman, and CEO of the MJ MD
            Global.
          </p>

          <br />

          <Button onClick={() => navigateTo("/philosophy")}>
            Learn more about MJMD Global Group
          </Button>
          {/* <HrefButtonInverse href="//www.Mj.com" title="Learn More">
            Read More
          </HrefButtonInverse> */}
        </Col>
      </Row>
    </Primary>

    {/* <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Compostable</h2>
          <p>
            We at MJ Global strive to protect our earth now. We are excited to create new history
            with the management of Touchless Tableware. Plasticware has become very harmful to
            marine life when in our oceans because it’s not biodegradable. Touchless compostable
            flatware and Touchless compostable chopsticks are not only unique in design but they are
            environmentally protective.
          </p>
          <br />
          <HrefButton href="//Mj.com/go-green">Read More</HrefButton>
        </Col>
        <Col xs={12} md={6}>
          <img src={MJSharks} alt="MJ Global Building" />
        </Col>
      </Row>
    </Alternate> */}
  </section>
);

export default Mj;
