import React from "react";
import Layout from "../components/Layout";

import Mj from "../components/Mj";

const MJPage = () => (
  <Layout>
    <Mj />
  </Layout>
);

export default MJPage;
